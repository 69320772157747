import { Component, Input } from '@angular/core';
import { LastUpdateUser } from '../../models/last-update-user.model';

@Component({
  selector: 'last-update-user',
  templateUrl: './last-update-user.component.html',
  styleUrls: ['./last-update-user.component.css']
})
export class LastUpdateUserComponent {

  @Input() data: LastUpdateUser;

  constructor() { }


}
