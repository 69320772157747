import { Component, Input } from '@angular/core'
import { ConfirmDialogData } from '../../models/confirm-dialog-data.model'
import { StatusDialog } from '../../models/status-dialog.model'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
    selector: 'common-confirm-dialog',
    templateUrl: './common-confirm-dialog.component.html',
    styleUrls: ['./common-confirm-dialog.component.css'],
})
export class CommonConfirmDialogComponent {
    @Input() status: StatusDialog
    @Input() data: ConfirmDialogData = new ConfirmDialogData('Titulo Não informado', 'Mensagem não informada')
    @Input() accept: Function
    @Input() reject: Function = () => {}

    private _display: boolean = false

    constructor(public router: Router, public activatedRoute: ActivatedRoute) {}

    openDialog() {
        this._display = true
    }

    acceptFunction() {
        this.close()
        this.accept()
    }

    rejectFunction() {
        this.close()
        this.reject()
    }

    get display() {
        return this._display
    }

    close() {
        this._display = false
    }
}
