import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MetismenuAngularModule } from '@metismenu/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MenubarModule } from 'primeng/menubar';
import { AppComponent } from './../../app.component';
import { CommonComponentsSharedModule } from './../../shared/common-components-shared.module';
import { LoginComponent } from './login/login.component';
import { TemplateComponent } from './template/template.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {CarouselModule} from 'primeng/carousel';
import { HomeComponent } from './home/home.component';
import {SplitterModule} from 'primeng/splitter';
import { CalendarModule } from 'primeng/calendar';
import {ToolbarModule} from 'primeng/toolbar';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService } from 'primeng/api';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {MegaMenuModule} from 'primeng/megamenu';
import {FieldsetModule} from 'primeng/fieldset';
import {PanelModule} from 'primeng/panel';
import {DividerModule} from 'primeng/divider';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    TemplateComponent,
    LoginComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    MetismenuAngularModule,
    MenubarModule,
    CommonComponentsSharedModule,
    TabMenuModule,
    CarouselModule,
    SplitterModule,
    CalendarModule,
    ToolbarModule,
    ConfirmPopupModule,
    ToastModule,
    AvatarModule,
    AvatarGroupModule,
    MegaMenuModule,   
    FieldsetModule,
    PanelModule,
    DividerModule,
    InputMaskModule,
    InputSwitchModule
  ],
  providers: [ConfirmationService, MessageService],
  exports: [
    AppComponent,
    UnauthorizedComponent,
    TemplateComponent,
    LoginComponent,
    HomeComponent
  ]
})
export class CoreModule { }
