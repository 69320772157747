import { StatusDialog } from './../../models/status-dialog.model'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
    selector: 'common-status-dialog',
    templateUrl: './common-status-dialog.component.html',
    styleUrls: ['./common-status-dialog.component.css'],
})
export class CommonStatusDialogComponent {
    @Input() list: Function = () => {}
    data: StatusDialog
    private _display: boolean = false

    constructor(public router: Router) {}

    openDialog(status: StatusDialog) {
        this.data = status
        this._display = true
    }

    get display() {
        return this._display
    }

    close() {
        this.list()
        this._display = false
    }
}
