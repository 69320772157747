import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  
  constructor(private location: Location, private service: AuthService, private router: Router) { }

  ngOnInit() {
  }

  login() {     
    this.router.navigate(['/login'])
  }

  esqueciminhasenha(){
    this.router.navigate(['/login'])
  }

  novoPublicador() {
    this.router.navigate(['/gestores-form'])
  }
}
