import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss']
})
export class FormFieldErrorComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('form-control') formControl: FormControl;

  @Input() isValidatorCustom = false;

  @Input() nomeErro: string;

  constructor() { }

  ngOnInit() {
  }

  public errorMenssagem(): string | null {
    if (!this.isValidatorCustom) {
      if (this.mustShowWeeoeMessage()) {
        return this.getErrorMensagem();
      } else {
        return null;
      }

    } else {
      if (this.mustShowWeeoeMessageCustom()) {
        return this.getErrorMensagem();
      } else {
        return null;
      }
    }

  }

  private mustShowWeeoeMessage(): boolean {
    return this.formControl.invalid && this.formControl.touched;
  }

  private mustShowWeeoeMessageCustom(): boolean {
    return this.formControl['_parent'].invalid && this.formControl['_parent'].touched;
  }

  getErrorMensagem(): string | null {
    // errors
    if (this.formControl.errors == null) {
      if (this.formControl['_parent'].errors !== null) {
        if (this.formControl['_parent'].errors.required) {
          return 'Dado obrigatório.';
        }
      }
    } else {

      if (this.formControl.errors.required) {
        return 'Dado obrigatório.';
      } else if (this.formControl.errors.minlength) {
        const requiredLength = this.formControl.errors.minlength.requiredLength;
        return `Deve ter no minímo ${requiredLength} caracteres.`;
      } else if (this.formControl.errors.maxlength) {
        const requiredLength = this.formControl.errors.maxlength.requiredLength;
        return `Deve ter no máximo ${requiredLength} caracteres.`;
      } else if (this.formControl.errors.email) {
        return `E-mail inválido.`;
      } else if (this.nomeErro) {
        return this.formControl.errors[this.nomeErro].message;
      }
    }
  }

}
