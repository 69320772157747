import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PublicadorDTO } from "src/app/shared/models/publicador.dto";
import { AuthService } from "src/app/shared/services/auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PerfilPublicadorService {

  constructor(
    private http: HttpClient,
    private authService: AuthService 
  ) {}

  getPublicadorLogado() {
    const params = { email: this.authService.currentUserValue.login }
    return this.http.get<PublicadorDTO[]>(environment.serverUrl + "publicador/find", { params } )
  }

  getFiltrarPublicador(param, filter) {  

    let params: any = '';

    if (filter == 'circuito') {
      params = { circuito: param } 
    }

    if (filter == 'congregacao') {
      params = { congregacao: param } 
    }
     
    return this.http.get<PublicadorDTO[]>(environment.serverUrl + "publicador/find", { params } ) 
  }

  editarPublicador(publicador: PublicadorDTO) {
    const { uuid, senha, telefone } = publicador

    return this.http.patch<PublicadorDTO>(
      environment.serverUrl + "publicador/" + uuid,
      { uuid, senha, telefone }
    )
  }
}
