<p-dialog [visible]="display" [style]="{ width: '50vw' }" [closeOnEscape]="false" [closable]="false" [modal]="true">
    <ng-template pTemplate="header" *ngIf="this.data.title">
        <h3 *ngIf="!data.title">{{ data.title }}</h3>
        <h3 *ngIf="data.title">Confirmação de {{ item?.situacao == 'A' ? 'Ativação' : 'Inativação' }}</h3>
    </ng-template>
    <div class="grid" *ngIf="item">
        <div class="col-12">
            <span>Deseja realmente {{ item?.situacao == 'A' ? 'reativar' : 'inativar' }} {{ this.data.message }} no sistema?</span>
        </div>
        <div class="field col-12">
            <div class="text-right">
                <small class="show-caracters">(Restam {{ 250 - getCaracteres('justificativa') }} caracteres)</small>
            </div>
            <form [formGroup]="form">
                <label> Motivo da {{ item?.situacao == 'A' ? 'Reativação' : 'Inativação' }}</label>
                <span class="p-float-label">
                    <!-- <label for="justificativa" [ngClass]="{'required-float-label': fieldMessageRequired('justificativa')}">Motivo da {{item?.situacao?.codigo == 'A' ? 'Reativação' : 'Inativação'}} *</label> -->
                    <textarea
                        [rows]="5"
                        placeholder="Digite aqui o motivo..."
                        formControlName="justificativa"
                        class="w-100"
                        id="justificativa"
                        required
                        minlength="20"
                        maxlength="250"
                        pInputTextarea
                    ></textarea>
                    <small *ngIf="fieldMessageRequired('justificativa')" class="msg-field-required"
                        >Campo de preenchimento obrigatório. Mínimo 20 caracteres.</small
                    >
                </span>
            </form>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button
            type="button"
            pButton
            icon="pi pi-check"
            class="p-button-success"
            label="{{ this.data.labelBtnAccept }}"
            (click)="acceptFunction()"
            [disabled]="this.form.invalid"
        ></button>
        <button
            type="button"
            pButton
            icon="pi pi-times"
            class="p-button-danger"
            label="{{ this.data.labelBtnReject }}"
            (click)="cancelFunction()"
        ></button>
    </ng-template>
</p-dialog>
<common-confirm-dialog
    #confirmCancelarAtivarInativarDialog
    [accept]="close"
    [reject]="closeCancelDialog"
    [data]="confirmCancelar"
></common-confirm-dialog>
