import { Component, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AtivoInativoEnum } from '../../enum/ativo-inativo.enum';
import { BtnAtivoInativoIconHint } from '../../models/btn-ativo-inativo-icon-hint.model';

@Component({
  selector: 'btn-ativo-inativo',
  templateUrl: './btn-ativo-inativo.component.html',
  styleUrls: ['./btn-ativo-inativo.component.css']
})
export class BtnAtivoInativoComponent implements OnDestroy {

  @Input() situacao: AtivoInativoEnum;
  @Input() btnIconHint: BtnAtivoInativoIconHint;
  @Input() btnDisabled: boolean = false;
  @Output() clickAction = new EventEmitter();

  constructor() { }

  ngOnDestroy(): void {
    this.clickAction.unsubscribe();
  }

  public click() {
    this.clickAction.emit();
  }

  get btnIcon(): string {
    const classDisabled = this.btnDisabled ? 'icon-ativo-custom-disabled' : 'icon-ativo-custom';
    return AtivoInativoEnum.A == this.situacao ? `pi ${classDisabled}` : `pi icon-inativo-custom`;
  }

  get btnHint() {
    return this.btnIconHint.getHint(this.situacao, this.btnDisabled)
  }
}
