<div class="grid overflow-hidden">
  <div  class="col-12 p-0 m-0">
    <p-toolbar class="p-0 m-0" styleClass="bg-blue-900">
      <div class="bg-blue-900">
        <!--<p-image src="assets/images/tpe.png" alt="Logo Tpe" height="40" (click)="goToHome()"></p-image>-->
        <h1 class="flex justify-content-start font-bold text-white ml-4 mb-0">TPE RIO</h1>
      </div>
      <div class="mt-2">
        <p-megaMenu class="sm:flex" 
          styleClass="sm:inline-flex bg-blue-900 menuprincipal" 
          [model]="menus" 
          (click)="clickMenu($event)">
        </p-megaMenu>
      </div>
      <div class="mr-4 p-2 pb-0 p-0 m-0 bg-blue-900">
        <p-avatar  styleClass="mr-2 bg-white"  [routerLink]="['/perfil-publicador']" [style]="{ 'color': '#0D004D', 'cursor': 'pointer'}" shape="circle">
          <label class=" text-2xl text-blue-900 pt-2" [style]="{'cursor': 'pointer'}">{{ nomeUsuario }}</label>
        </p-avatar>
        <a class="menu-logout" (click)="logout()">
          <i class="pi pi-sign-out mr-2"></i>
          <span class="text-white">Sair</span>
        </a>
      </div>
    </p-toolbar>
  </div>  
  <div class="col-12  bg-white">
    <section>
      <router-outlet></router-outlet>
    </section>
  </div>
</div>