import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { AtivoInativoEnum } from '../../enum/ativo-inativo.enum'
import { ConfirmDialogData } from '../../models/confirm-dialog-data.model'
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component'
import { AtivoInativoDialogData } from './../../models/ativo-inativo-dialog-data.model'

@Component({
    selector: 'common-ativo-inativo-dialog',
    templateUrl: './common-ativo-inativo-dialog.component.html',
    styleUrls: ['./common-ativo-inativo-dialog.component.css'],
})
export class CommonAtivoInativoDialogComponent implements OnInit {
    @Input() data: ConfirmDialogData = new ConfirmDialogData('Titulo Não informado', 'Mensagem não informada')
    @Input() confirmCancelar: ConfirmDialogData = new ConfirmDialogData('Titulo Não informado', 'Mensagem não informada')
    @Input() execute: Function

    item: AtivoInativoDialogData

    form: FormGroup
    @ViewChild('confirmCancelarAtivarInativarDialog') confirmCancelarAtivarInativarDialog: CommonConfirmDialogComponent

    private _display: boolean = false
    constructor() {}

    ngOnInit(): void {
        this.form = new FormGroup({
            justificativa: new FormControl('', [Validators.required, Validators.minLength(20), Validators.maxLength(250)]),
        })
    }

    fieldMessageRequired(field): boolean {
        return this.form.get(field).invalid && this.form.get(field).dirty
    }

    openDialog(indicacaoAtivo: AtivoInativoEnum, id: number) {
        this.item = new AtivoInativoDialogData(AtivoInativoEnum.A == indicacaoAtivo ? AtivoInativoEnum.I : AtivoInativoEnum.A, id)
        this._display = true
    }

    acceptFunction() {
        this.getJustificativaData()
        this.execute(this.item)
        this.form.reset()
        this._display = false
    }

    cancelFunction() {
        this.confirmCancelarAtivarInativarDialog.openDialog()
    }

    get display() {
        return this._display
    }

    close = () => {
        this.form.reset()
        this.confirmCancelarAtivarInativarDialog.close()
        this._display = false
    }

    closeCancelDialog = () => {
        this.confirmCancelarAtivarInativarDialog.close()
    }

    public getCaracteres(field: string): number {
        const value = new String(this.form.get(field).value)
        return value ? value.length : 0
    }

    getJustificativaData(): AtivoInativoDialogData {
        this.item.justificativa = this.form.get('justificativa').value
        return { ...this.item }
    }
}
