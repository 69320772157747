<div class="grid">
    <div class="col-12">
        <p-paginator
            [rows]="paginator.size"
            [first]="paginator.first"
            [totalRecords]="paginator.totalElements"
            (onPageChange)="changePaginator($event)"
            [showFirstLastIcon]="true"
            [showJumpToPageInput]="true"
            [pageLinkSize]="10"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            [showCurrentPageReport]="true"
        ></p-paginator>
    </div>
</div>
<!--<div class="grid">
  <div class="col-12 col-md-6">
    <form [formGroup]="form">
      <span>Exibir</span>
      <span class="ml-1 mr-1">
          <select formControlName="itensPerPage" class="select-paginator">
            <option *ngFor="let item of listItensPorPage" value="{{item}}">{{item}}</option>
          </select>
      </span>
      <span> itens por página | {{firstItem}} até {{ lastItem }} de {{totalElements}} itens</span>
    </form>
  </div>
  <div class="flex col-12 col-md-6 justify-content-end">
    <last-update-user [data]="lastUpdateUser"></last-update-user>
  </div>
</div>-->
