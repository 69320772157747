import { Data } from "@angular/router";

export class AgendamentoDto {
    uuid?: string;
    horainicio?: string;
    horafim?: string;  
    publicadoruuid?: string;
    principaluuid?: string;  
    auxiliaruuid?: string;
    pontouuid?: string;  
    data?: Data;    
}