import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ConfirmDialogData } from '../../models/confirm-dialog-data.model'
import { CommonConfirmDialogComponent } from '../common-confirm-dialog/common-confirm-dialog.component'
import { ConfirmDialogExclusaoData } from './../../models/confirm-dialog-exclusao-data.model'

@Component({
    selector: 'common-exclusao-dialog',
    templateUrl: './common-exclusao-dialog.component.html',
    styleUrls: ['./common-exclusao-dialog.component.css'],
})
export class CommonExclusaoDialogComponent implements OnInit {
    @Input() data: ConfirmDialogExclusaoData
    @Input() confirmCancelar: ConfirmDialogData
    @Input() execute: Function
    private itensExclusao: any[] = []

    @ViewChild('confirmCancelarExclusaoDialog') confirmCancelarExclusaoDialog: CommonConfirmDialogComponent

    private _display: boolean = false
    constructor() {}

    ngOnInit(): void {}

    openDialog(itensExclusao: any[]) {
        this.itensExclusao = itensExclusao
        this._display = true
    }

    acceptFunction() {
        this.execute(this.itensExclusao)
        this._display = false
    }

    cancelFunction() {
        this.confirmCancelarExclusaoDialog.openDialog()
    }

    get display() {
        return this._display
    }

    close = () => {
        this.itensExclusao = []
        this.confirmCancelarExclusaoDialog.close()
        this._display = false
    }

    closeCancelDialog = () => {
        this.confirmCancelarExclusaoDialog.close()
    }
}
