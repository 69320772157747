import { MenuItem } from 'primeng/api'
import { AuthService } from 'src/app/shared/services/auth.service'
import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { STORAGE_KEYS } from 'src/app/shared/config/storage_keys.config'
import { EventoDTO } from 'src/app/shared/models/evento.dto'
import * as _ from 'underscore'

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css'],
})
export class TemplateComponent implements OnInit {
    nomeUsuario: string
    _isAuthenticated = false
    menus: MenuItem[];   
    user

    pageSelect = JSON.parse(localStorage.getItem('tela'));  
    telaHome: boolean = false;
    telaCadastrar: boolean = false;
    mensagemCabecalho: string;
    menuSelecionado: string;

    habilitarEventosEspeciais: boolean = false;
    eventoAutorizadoPorUsuario: EventoDTO;

    constructor(public router: Router,  private oidcAuthService: AuthService) { }    

    ngOnInit(): void {
        this.user = this.oidcAuthService.getLoggedUser();                
        this.initMenuPrincipal();   
        this.montaIniciaisNome();
    }    

    get isAutenticated() {
        return this._isAuthenticated
    }

    goToHome() {
        this.router.navigate(['/'])
    }

    logout() {
        this.oidcAuthService.logout();
        this.router.navigate(['/unauthorized'])
    }

    montaIniciaisNome() {
        var arrayNome= this.user.nome.split(' ');
        this.nomeUsuario = arrayNome[0].substr(0, 1).toUpperCase();
        this.nomeUsuario = this.nomeUsuario + arrayNome[1].substr(0, 1).toUpperCase();
    }

    clickMenu(event: any) {
        this.menuSelecionado = event.target.firstElementChild.innerText;
        localStorage.setItem(STORAGE_KEYS.selectedMenu, this.menuSelecionado);
    }

    initMenuPrincipal() {       
        let isAdmin = (this.user.perfil.nome == 'ADMIN' || this.user.perfil.nome == 'ADMINPONTO');
        let user = localStorage.getItem(STORAGE_KEYS.currentUser);
        let userJson = JSON.parse(user);
        
        //se futuramente for possível ter mais de 1 evento ativo numa mesma época por usuário, alterar aqui
        this.eventoAutorizadoPorUsuario = _.last(userJson.eventos);

        if (this.eventoAutorizadoPorUsuario != null) {
            this.habilitarEventosEspeciais = true;           
        } else this.habilitarEventosEspeciais = false;

        if(this.habilitarEventosEspeciais) {
            this.menus = [
                { label: 'Início', routerLink: ['/home'], icon: 'pi pi-home'},
                { label: 'Agendamento', routerLink: ['/agendamentos/'] },
                { label: 'Eventos Especiais', routerLink: ['/eventosespeciais'] },            
                { label: 'Administração', routerLink: ['/administracao'], visible: isAdmin },
                { label: 'Relatórios', routerLink: ['/relatorios'], visible: isAdmin },
                //{ label: 'Pesquisar', routerLink: ['/pesquisar'], disabled: true }
            ]
        }
        else {
            this.menus = [
                { label: 'Início', routerLink: ['/home'], icon: 'pi pi-home'},
                { label: 'Agendamento', routerLink: ['/agendamentos/'] },           
                { label: 'Administração', routerLink: ['/administracao'], visible: isAdmin },
                { label: 'Relatórios', routerLink: ['/relatorios'], visible: isAdmin },                
            ]
        }
    }    
}
