<div class="flex justify-content-center flex-wrap card-container" id="wrapper">
    <div id="login">
        <form class="card">
            <div class="card-header">
                <img src="assets/images/tpe.png" class="tpe_inicio">
            </div>
            <div class="card-footer">
                <button pButton pRipple type="button" label="Entrar" class="p-button-raised p-button-secondary font-bold bg-blue-900 mb-2" (click)="login()">                    
                </button>
                <div class="flex justify-content-center">
                    <a (click)="esqueciminhasenha()" class="text-white recuperar_senha">Esqueceu a senha?</a>
                </div>
            </div>
        </form>
    </div>
</div>