
<form [formGroup]="form">
  <div class="flex grid">

    <div class="col-12 col-lg-6">

      <label>Situação da {{labelSituacao}} *</label>

      <span class="p-float-label">

        <div class="col-12 col-lg-6">

          <div class="row">
            <div *ngFor="let radioOption of radioOptions" class="p-field-checkbox mr-5">
              <p-radioButton [inputId]="radioOption.key" name="indicacaoAtivo" [value]="radioOption" formControlName="indicacaoAtivo" (onClick)="updateIndicacaoAtivo()"></p-radioButton>
              <label class="align ml-2" [for]="radioOption.key">{{radioOption.name}}</label>
            </div>
          </div>

        </div>

        <div class="text-right w-100">

          <small *ngIf="fieldMessageRequired('indicacaoAtivo')" class="msg-field-required">Preenchimento obrigatório</small>

        </div>

      </span>

    </div>

  </div>
  <div class="flex grid" *ngIf="data !==  this.form.controls['indicacaoAtivo'].value.key">
    <div class="field col-12" >

      <div class="row col-12 w-50">

        <div class="text-left w-50">
          <label> Justificativa da {{data == 'A' ? 'Inativação' : 'Ativação'}}*</label>
        </div>

        <div class="text-right w-50">
          <small class="show-caracters">(Restam {{ 250 - getCaracteres('justificativa') }} caracteres)</small>
        </div>

      </div>

      <span class="p-float-label">
        <textarea [rows]="5" placeholder="Digite aqui a justificativa..." formControlName="justificativa" class="w-50 not-resize" id="justificativa" required minlength="20" maxlength="250" pInputTextarea (change)="updateJustificativa()"></textarea>
      </span>

      <small *ngIf="fieldMessageRequired('justificativa')" class="msg-field-required">Campo de preenchimento obrigatório. Mínimo 20 caracteres.</small>
    </div>
  </div>
</form>
