import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  AfterViewInit {

  showMenu = true;
  constructor(private primengConfig: PrimeNGConfig, private router: Router) {}


  ngAfterViewInit() {
    this.primengConfig.ripple = true;

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        if(event.url === '/unauthorized' || event.urlAfterRedirects.length <= 1) {
          this.showMenu = false;
        }
      }
    });
    this.primengConfig.setTranslation({
      accept: 'Aceitar',
      reject: 'cancelar',
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar',
  });
  }
}
