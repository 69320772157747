import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PublicadorDTO } from 'src/app/shared/models/publicador.dto';
import { PerfilPublicadorService } from '../../administracao/pages/service/perfil-publicador.service';

@Component({
  selector: 'app-perfil-publicador',
  templateUrl: './perfil-publicador.component.html',
  styleUrls: ['./perfil-publicador.component.css']
})
export class PerfilPublicadorComponent implements OnInit {

  cadastroForm: FormGroup
  publicador: PublicadorDTO

  constructor(
    private formBuilder: FormBuilder,
    private publicadorService: PerfilPublicadorService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.iniciarFormulario()
    this.pegarPublicadorLogado()
  }

  pegarPublicadorLogado() {
    this.publicadorService.getPublicadorLogado()
      .subscribe(publicador => {
        this.publicador = publicador[0]

        this.publicador.uuidcongregacao = this.publicador.congregacao.uuid
        this.publicador.uuidperfil = this.publicador.perfil.uuid
      })
  }

  iniciarFormulario() {
    this.cadastroForm = this.formBuilder.group({
      telefone: new FormControl('', [Validators.required]),
      senha: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmarSenha: new FormControl('', [Validators.required, Validators.minLength(8)]),
    })
  }

  editar() {
    const { senha, confirmarSenha, telefone } = this.cadastroForm.value

    const formValido = {
      senha: "",
      telefone: ""
    }

    if (senha != confirmarSenha)
      formValido.senha = "As Senhas não são iguais"
    else if (senha.length < 8 && senha.trim() != "")
      formValido.senha = "A senha precisa ter no mínimo 8 caracteres"

    if (telefone == "")
      formValido.telefone = "Telefone Inválido"


    for (const key in formValido) {
      const element = formValido[key];

      if (element) {
        this.messageService.add({
          severity:"error",
          summary: element,
        })
        return
      }}

    this.publicador.telefone = telefone
    this.publicador.senha = senha ? senha : null

    this.publicadorService.editarPublicador(this.publicador)
      .toPromise()
      .then(publicador => {
        console.log(publicador)

        this.messageService.add({
          severity:"success",
          summary: "Perfil Editado",
          detail: "Seu perfil foi editado com sucesso"
      })})
      .catch(console.log)
      .then(() => this.pegarPublicadorLogado())
  }
}
