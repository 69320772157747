import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(@Inject(Injector) private injector: Injector,  private router: Router, private oidcAuthService: AuthService) { }

  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: HttpErrorResponse | any): void {
    if (error.status === 400) {
      error.error.forEach(err => {
        this._toastrService.warning(err.message, 'Mensagem de erro');
      });
    } else if (error.status === 401 || error.status === 403) {
      this._toastrService.error('Você não está autorizado a acessar este recurso ou sua sessão expirou. Faça o Login novamente!');
      this.oidcAuthService.logout();
      this.router.navigate(['/login'])
    }
  }
}
