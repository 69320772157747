import { AtivoInativoEnum } from './../../enum/ativo-inativo.enum';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'situacao',
  templateUrl: './situacao.component.html',
  styleUrls: ['./situacao.component.scss']
})
export class SituacaoComponent implements OnInit {


  form: FormGroup;
  radioOptions: any[];
  @Input() labelSituacao: string = null;
  @Input() data: AtivoInativoEnum = null;
  @Output() indicacaoAtivoChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() justificativaChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    console.log(this.data)
    this.radioOptions = [{name: 'Ativo', key: AtivoInativoEnum.A}, {name: 'Inativo', key: AtivoInativoEnum.I}];
    this.initForm();
  }

  initForm(){
    this.form = new FormGroup({
      indicacaoAtivo: new FormControl(null, [Validators.required]),
      justificativa: new FormControl(null, [Validators.required, Validators.minLength(20), Validators.maxLength(250)])
    });

    this.data === AtivoInativoEnum.A ?
      this.form.patchValue( {indicacaoAtivo: this.radioOptions[0] }) :
      this.form.patchValue( {indicacaoAtivo: this.radioOptions[1] });
  }

  updateIndicacaoAtivo() {
    this.indicacaoAtivoChange.emit(this.form.controls['indicacaoAtivo'].value.key);
  }

  updateJustificativa() {
    this.justificativaChange.emit(this.form.controls['justificativa'].value);
    // console.log(this.form.controls['justificativa'].value);
  }

  fieldMessageRequired(field): boolean {
    return this.form.get(field).invalid && this.form.get(field).dirty;
  }

  public getCaracteres(field: string): number {
    const value = new String(this.form.get(field).value);
    return value ? value.length : 0;
  }
}
