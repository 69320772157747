import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CredenciaisDTO } from 'src/app/shared/models/credenciais.dto';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [DialogService, MessageService]
})
export class LoginComponent {

  mensagemSucesso: string;
  errors: String[];
  injector: any

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  isEsqueciSenha = false;
  error = '';
  visible: boolean = false;


  creds: CredenciaisDTO = {
    login: "",
    senha: ""
  };


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    public messageService: MessageService,
    public dialogService: DialogService
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      email: ['']
    });
  }

  showDialog() {
      this.visible = true;
  }

  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService)
  }

  esqueciminhasenha() {
    this.isEsqueciSenha = true;
  }

  submitEsqueciSenha() {
    this.loading = true;
    if (!this.f.email.value) {
      this.loading = false;
      this.errors = ['Preencha seu email'];
      return;
    }

    this.authService
      .esqueciminhasenha(this.f.email.value)
      .subscribe(response => {
        //console.log(response);
        this.loading = false;
        this.messageService.add({ severity: 'success', summary: 'Confirmação', detail: 'Email com uma nova senha enviado com sucesso!', life: 3000 })
        this.isEsqueciSenha = false;
      }, errorResponse => {
        this.loading = false;
        console.log("entrou aqui", errorResponse.error)
        if (errorResponse.error && errorResponse.error.code === '500') {
          console.log("entrou aqui 1", errorResponse.error)
          this.errors = [errorResponse.error.text];
        } else if (errorResponse.status === 401) {
          this.errors = ['Acesso não autorizado!'];
        } else {
          console.log("entrou aqui 2")
          this.errors = ['Login/Email inválidos ou não cadastrados!'];
        }
      });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.creds.login = this.f.username.value;
    this.creds.senha = this.f.password.value;

    this.authService
      .login(this.creds)
      .subscribe(response => {
        this.router.navigate(['/home']);
        this.loading = false;
      }, errorResponse => {
        this.loading = false;
        if (errorResponse.error && errorResponse.error.code === '500') {
          const errorMessage = errorResponse.error.text;
          if (errorMessage.includes('limite de acessos alcançados')) {
            this.errors = ['Estamos com muitos usuários acessando o sistema no momento. Tente novamente mais tarde.'];
          } else {
            this.errors = [errorMessage];  // Exibe a mensagem de erro padrão
          }
        } else if (errorResponse.status === 401) {
          this.errors = ['Acesso não autorizado!'];
        } else {
          this.errors = ['Usuário e/ou senha incorreto(s).'];
        }
      });
  }
}
