export const MESES = [
  { id: 0, value: 'Janeiro' },
  { id: 1, value: 'Fevereiro' },
  { id: 2, value: 'Março' },
  { id: 3, value: 'Abril' },
  { id: 4, value: 'Maio' },
  { id: 5, value: 'Junho' },
  { id: 6, value: 'Julho' },
  { id: 7, value: 'Agosto' },
  { id: 8, value: 'Setembro' },
  { id: 9, value: 'Outubro' },
  { id: 10, value: 'Novembro' },
  { id: 11, value: 'Dezembro' }
];
