import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable, EventEmitter } from "@angular/core"
import { EventoDTO } from "src/app/shared/models/evento.dto"
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { ModoTela } from '../../../base/enum/modo.tela.enum';
import { invalid } from "@angular/compiler/src/render3/view/util";
import { DatePipe, formatDate } from "@angular/common";
import * as moment from "moment";
import * as _ from "underscore";

@Injectable({
  providedIn: 'root'
})
export class CadastroEventoService {

  httpOptions: any;
  eventoASerEditado: EventoDTO;
  emitirEventoAEditar = new EventEmitter<EventoDTO>();
  emitirModoTela = new EventEmitter<ModoTela>();
  emitirFinalizar = new EventEmitter<boolean>();
  static eventoASerEditado = new EventEmitter<EventoDTO>();
  static modoTelaEmitter = new EventEmitter<ModoTela>();
  static finalizarEmitter = new EventEmitter<boolean>();

  constructor(private http:HttpClient) { }

  private options = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  setEventoASerEditado(evento: EventoDTO, modoTela: ModoTela) {
    this.eventoASerEditado = evento;
    this.emitirEventoAEditar.emit(evento);
    this.emitirModoTela.emit(modoTela);
    CadastroEventoService.eventoASerEditado.emit(evento);
    CadastroEventoService.modoTelaEmitter.emit(modoTela);
  }

  getEventoASerEditado(): EventoDTO {
    return this.eventoASerEditado;
  }

  listarEventos() {
    return this.http.get<EventoDTO[]>(environment.serverUrl + 'evento/ativos');
  }

  listarEventosEstruturaCompleta() {
    return this.http.get<any[]>(environment.serverUrl + 'evento/ativos');
  }
  
  cadastrarEventoForm(formValue) {
    let options : any;
    return this.http.post(environment.serverUrl + 'evento', formValue, options);
  }

  editarEventoForm(formValue) {
    let options : any;
    return this.http.put<EventoDTO>(environment.serverUrl + 'evento/', formValue, options)
  }

  excluirEvento(evento: EventoDTO) {
    return this.http.delete<any>(environment.serverUrl + 'evento/' + evento.uuid, this.options)
  }

  stringToDate(dateToTransform: string): Date {
    let date = dateToTransform.split('/');
    const dateObj=new Date(Number(date[2]), Number(date[1]), Number(date[0]));
    return dateObj;
  }

  validaEvento(evento: EventoDTO,  listaEventos: EventoDTO[], modoTela: ModoTela) {
    const eventoValidado = {
      nome: '',
      dataInicio: '',
      dataFim: '',
      isValid: true
    }

    var dtInicial = moment(evento.dataInicio, "DD/MM/YYYY").toDate(); 
    var dtFinal = moment(evento.dataFim, "DD/MM/YYYY").toDate(); 

    var dataInicialValida = !isNaN(dtInicial.valueOf());
    var dataFinalValida = !isNaN(dtFinal.valueOf());

    if (!evento.nome) {
      eventoValidado.nome = 'O nome do evento não pode estar vazio';
    } else {

    //se o modo de tela for de edição, não vamos testar se o nome do evento já está na lista.
    if(modoTela === ModoTela.MODO_CADASTRO) {
      var existeEvento = listaEventos.filter(x=>x.nome == evento.nome);
    
      if(existeEvento.length != 0) {
        eventoValidado.nome = 'Já existe um evento com este nome.';
      }
    }

    if(!dataInicialValida) {
      eventoValidado.dataInicio = 'Data inicial inválida.';
    }

    if(!dataFinalValida) {
      eventoValidado.dataFim = 'Data final inválida.';
    }

    if(dataInicialValida && dataFinalValida) {
      if(dataFinalValida < dataInicialValida) {
        eventoValidado.dataFim = 'A data final não pode ser anterior à data de início do evento.';
      }
    }

      //regex apenas com letras
      const patternNome = /^[A-Za-z0-9 áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/;
      var testeRegexNome = patternNome.test(evento.nome);
      if(!testeRegexNome) {
        eventoValidado.nome = 'O nome do evento deve conter apenas letras.';
      }
    }

    if (eventoValidado.nome || eventoValidado.dataInicio || eventoValidado.dataFim) {
      eventoValidado.isValid = false
      return eventoValidado
    }

    return eventoValidado
  }
}
