import { StatusDialog } from './../../models/status-dialog.model';
import { CommonStatusDialogComponent } from './../common-status-dialog/common-status-dialog.component';
import { ButtonActions } from '../../models/button-actions.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'common-page',
  templateUrl: './common-page.component.html',
  styleUrls: ['./common-page.component.css']
})
export class CommonPageComponent implements OnInit {

  @Input() loading: boolean = false;
  @Input() btnVoltar: ButtonActions;
  @Input() nomeFuncionalidade: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() actions: ButtonActions[] = [];

  @ViewChild("statusDialog") statusDialog: CommonStatusDialogComponent;

  constructor() { }

  ngOnInit(): void {
  }

  showStatus(status: StatusDialog) {
    this.statusDialog.openDialog(status);
  }
}
