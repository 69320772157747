<div class="common-page p-3 {{ loading ? 'loading-content' : '' }}">
    <div class="container-page">
        <div class="grid">
            <div class="flex align-items-center col-12">
                <a *ngIf="btnVoltar" (click)="btnVoltar.callback()" class="btn-voltar">
                    <i class="pi pi-chevron-left icon-voltar"></i> <span>{{ btnVoltar.label }}</span>
                </a>
            </div>
        </div>
        <div class="grid">
            <div class="flex align-items-center col-12 col-lg-9 col-md-8">
                <span class="nome-funcionalidade" *ngIf="nomeFuncionalidade">{{ nomeFuncionalidade }}</span>
            </div>
        </div>
        <div class="grid">
            <div class="flex align-items-center col-12 col-lg-8 col-md-6">
                <span class="title-page" *ngIf="title">{{ title }}</span>
            </div>
            <div class="flex align-items-center justify-content-end col-12 col-lg-4 col-md-6">
                <button
                    pButton
                    type="button"
                    *ngFor="let act of actions"
                    label="{{ act.label }}"
                    class="m-1 {{ act.cssClass }}"
                    (click)="act.callback()"
                    [disabled]="act.disabled"
                ></button>
            </div>
        </div>
        <div class="flex grid">
            <div class="flex col-12">
                <div class="page-card">
                    <div class="col-12" *ngIf="subtitle">
                        <h4>{{ subtitle }}</h4>
                    </div>
                    <hr *ngIf="subtitle" />
                    <div class="col-12">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </div>
        <common-status-dialog #statusDialog></common-status-dialog>
    </div>
    <p-progressSpinner
        *ngIf="loading"
        [style]="{ width: '50px', height: '50px' }"
        styleClass="custom-spinner"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".8s"
    ></p-progressSpinner>
</div>
