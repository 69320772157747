<p-dialog [visible]="display" [style]="{ width: this.data.width }" [closeOnEscape]="false" [closable]="false" [modal]="true">
    <ng-template pTemplate="header" *ngIf="this.data.title">
        <h3>{{ this.data.title }}</h3>
    </ng-template>
    <div class="grid align-itens-center" *ngIf="status">
        <div class="col-12 text-center"><i class="pi {{ status.iconStatus }}" style="font-size: 2rem"></i></div>
        <div class="col-12 text-center text-bold"><span [innerHtml]="status.statusMessage"></span></div>
    </div>
    <div class="grid align-itens-center mt-1" *ngIf="data">
        <div class="col-12 text-center text-bold"><span [innerHtml]="this.data.message"></span></div>
    </div>
    <ng-template pTemplate="footer">
        <button
            type="button"
            pButton
            icon="pi pi-check"
            class="p-button-success"
            label="{{ this.data.labelBtnAccept }}"
            (click)="acceptFunction()"
        ></button>
        <button
            type="button"
            pButton
            icon="pi pi-times"
            class="p-button-danger"
            label="{{ this.data.labelBtnReject }}"
            (click)="rejectFunction()"
        ></button>
    </ng-template>
</p-dialog>
