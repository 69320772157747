<p-dialog [visible]="display" [style]="{ width: '50vw' }" [closeOnEscape]="false" [closable]="false" [modal]="true">
    <ng-template pTemplate="header" *ngIf="data.title">
        <div class="grid">
            <div class="col-12">
                <h3 *ngIf="data.title">{{ data.title }}</h3>
            </div>
        </div>
    </ng-template>
    <div class="grid" *ngIf="data">
        <div class="col-12">
            <p [innerHtml]="data.message" class="text-confirm"></p>
            <p [innerHtml]="data.descItens"></p>
        </div>
    </div>
    <ng-template pTemplate="footer" *ngIf="data">
        <button
            type="button"
            pButton
            icon="pi pi-check"
            class="p-button-success"
            label="{{ data.labelBtnAccept }}"
            (click)="acceptFunction()"
        ></button>
        <button
            type="button"
            pButton
            icon="pi pi-times"
            class="p-button-danger"
            label="{{ data.labelBtnReject }}"
            (click)="cancelFunction()"
        ></button>
    </ng-template>
</p-dialog>
<common-confirm-dialog #confirmCancelarExclusaoDialog [accept]="close" [reject]="closeCancelDialog" [data]="confirmCancelar"></common-confirm-dialog>
