export class ConstantesMensagens {
  public static readonly AVISO_COMPO_OBRIGATORIO =
    'Esse campo é obrigatório. Lembre-se de preencher.';

  public static readonly MENSAGEM_DESCRICAO_ALERTAS =
    'Por favor, tente novamente mais tarde.';

  public static readonly MENSAGEM_DESCRICAO_HOME =
    'Seja bem-vindo ao TPE';

  public static readonly MENSAGEM_DESCRICAO_RELATORIOS =
    'Relatório de Programação';
}
