<div *ngIf="!isEsqueciSenha"
     class="flex justify-content-center flex-wrap card-container" id="wrapper">       
    <div id="login">
        <form class="card">
            <div class="card-header  mb-4">
                <img src="assets/images/tpe.png" class="tpe_inicio">
            </div>
            <div class="card-body">
                <label for="usuario" class="text-white mb-2 text-lg">Login</label>
                <div class="alert alert-danger" *ngFor="let erro of errors">
                    {{ erro }}
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <input type="text" formControlName="username" class="form-control" placeholder="Usuário"
                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required" class="text-white">Informar o usuário é obrigatório</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" formControlName="password" class="form-control" placeholder="senha"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required" class="text-white">Informar uma senha é obrigatório</div>
                        </div>
                    </div>
                    <div class="flex justify-content-center">
                        <button pButton pRipple type="submit" label="Entrar" class="p-button-raised p-button-secondary font-bold bg-blue-900 mb-2"></button>
                    </div>
                    <div class="flex justify-content-center">
                        <button pButton class="text-white p-button-link text-sm border-noround" (click)="showDialog()" label="Esqueceu o usuário?"></button>
                    </div>  
                    <div class="flex justify-content-center">
                        <a (click)="esqueciminhasenha()" class="text-white recuperar_senha">Esqueceu a senha?</a>
                    </div>                   
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </form>
    </div>
</div>
<div *ngIf="isEsqueciSenha"
     class="flex justify-content-center flex-wrap card-container" id="wrapper">
    <div id="login">
        <form class="card">
            <div class="card-header  mb-4">
                <img src="assets/images/tpe.png" class="tpe_inicio">
            </div>
            <div class="card-body">
                <label for="usuario" class="text-white mb-2 text-lg">Digite seu email</label>
                <div class="alert alert-danger" *ngFor="let erro of errors">
                    {{ erro }}
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="submitEsqueciSenha()">
                    <div class="form-group">
                        <input type="text" formControlName="email" class="form-control" placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required" class="text-white">Informar o email é obrigatório</div>
                        </div>
                    </div>
                    
                    <div class="flex justify-content-center">
                        <button pButton pRipple type="submit" label="Enviar" class="p-button-raised p-button-secondary font-bold bg-blue-900 mb-2"></button>
                    </div>
                    <div class="flex justify-content-center">
                        <a href="#" class="text-white">Voltar</a>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </form>
    </div>
</div>
<p-dialog header="Recuperar Usuário" [(visible)]="visible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <div class="justify-content-center text-sm m-0 p-0">
        <label class="font-italic">Não lembra qual e-mail você usou para o site do TPE? Envie um e-mail para a equipe de suporte do seu circuito:</label>
        <label class="ml-4">RJ - 07: tpesuporterj07@gmail.com</label><br>
        <label class="ml-4">RJ - 08: tpesuporterj08@gmail.com</label><br>
        <label class="ml-4">RJ - 09: tpesuporterj09@gmail.com</label><br>
        <label class="ml-4">RJ - 12: tpesuporterj12@gmail.com</label><br>
        <label class="ml-4">RJ - 22: tpesuporterj22@gmail.com</label><br>
        <label class="ml-4">RJ - 38: tpesuporterj38@gmail.com</label>     
        <label class="font-italic">Congregações de idiomas diferente do português podem usar qualquer um dos e-mails!</label>            
    </div>
</p-dialog>
<p-toast></p-toast>