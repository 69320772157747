import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CredenciaisDTO } from '../models/credenciais.dto';
import { LocalUser } from '../models/local_user';
import jwtDecode from "jwt-decode";

@Injectable()
export class AuthService {

  private currentUserSubject: BehaviorSubject<LocalUser>;
  public currentUser: Observable<LocalUser>;
  private customHttpClient: HttpClient;
  ;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.currentUserSubject = new BehaviorSubject<LocalUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.customHttpClient = new HttpClient(handler);
  }

  get currentUserValue(): LocalUser {
    return this.currentUserSubject.value;
  }

  login(creds: CredenciaisDTO): Observable<any> {
    const tokenUrl = environment.serverUrl + environment.obterTokenUrl;
    return this.customHttpClient.post<any>(tokenUrl, creds)
      .pipe(map(user => {
        this.getUser(user)
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  esqueciminhasenha(email : string): Observable<any> {
    const tokenUrl = environment.serverUrl + environment.esquecisenha;
    return this.customHttpClient.post<any>(tokenUrl, email)
      .pipe(map(response => {
      }));
  }

  getLoggedUser(){
    let payload:any = localStorage.getItem('currentUser');
    payload = JSON.parse(payload);
    let tempDecode: any = jwtDecode(payload.token);
    tempDecode = JSON.parse(tempDecode.sub);
    return tempDecode;
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getUser(user) {
    const token = JSON.parse(localStorage.getItem('token'));
    const usuarioInfo = JSON.parse(atob(user.token.split('.')[1]));
    const usuaruiDecode = JSON.parse(usuarioInfo['sub']);
    
    user.uuid = usuaruiDecode.uuid;
    user.uuid = usuaruiDecode.uuid;
    user.nome = usuaruiDecode.nome;
    user.perfil = usuaruiDecode.perfil.nome;
    localStorage.setItem('currentUser', JSON.stringify(user));    
  }
}
