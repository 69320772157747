import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BtnIconHint } from '../../models/btn-icon-hint.model';

@Component({
  selector: 'btn-icon-hint',
  templateUrl: './btn-icon-hint.component.html',
  styleUrls: ['./btn-icon-hint.component.css']
})
export class BtnIconHintComponent implements OnDestroy {

  @Output() clickAction = new EventEmitter();
  @Input() btnIconHint: BtnIconHint;
  @Input() btnDisabled: boolean = false;

  constructor() {}

  ngOnDestroy(): void {
    this.clickAction.unsubscribe();
  }

  click() {
    this.clickAction.emit();
  }

  get btnHint() {
    return this.btnDisabled ? this.btnIconHint.btnHintDisabled : this.btnIconHint.btnHint;
  }

  get btnIcon() {
    return this.btnDisabled ? this.btnIconHint.btnIconDisabled : this.btnIconHint.btnIcon;
  }
}
