<div class="grid flex flex-wrap md:flex-wrap">
  <div class="col-12 p-0 m-0 surface-400 ">
    <label class="text-2xl text-900 mt-2 flex justify-content-center font-light">Atualizar Perfil</label>
</div>
<div class="col-12 p-0 m-0 flex justify-content-center">
  <div class="card col-12 sm:w-full xl:w-8">
    <form [formGroup]="cadastroForm">

      <div class="md:flex m-2">
        <div class="field col primeiro-campo ">
          <label for="nome" class="w-full">Nome:</label>
          <input pInputText disabled [value]="publicador.nome" type="text" name="nome" class="w-full" />
        </div>

        <div class="field col segundo-campo ">
          <label for="genero" class="w-full">Gênero:</label>
          <input *ngIf="publicador.genero == 'M'" pInputText disabled value="Masculino" type="text" name="genero" class="w-full" />
          <input *ngIf="publicador.genero == 'F'" pInputText disabled value="Feminino" type="text" name="genero" class="w-full" />
        </div>
      </div>


      <div class="md:flex m-2">
        <div class="field col primeiro-campo">
          <label for="email" class="w-full">Email:</label>
          <input pInputText disabled [value]="publicador.email" type="text" name="email" class="w-full" />
        </div>

        <div class="field col segundo-campo">
          <label for="telefone" class="w-full">Celular:</label>
          <p-inputMask  mask="(99) 99999-9999" styleClass="max-w-9rem"  formControlName="telefone" [ngModel]="publicador.telefone" name="telefone">            
          </p-inputMask>
        </div>
      </div>

      <div class="md:flex m-2">
        <div class="field col-8 primeiro-campo">
          <label for="congregacao" class="w-full">Congregação:</label>
          <input pInputText disabled [value]="publicador.congregacao.nome" name="congregacao" class="w-full" />
        </div>

        <div class="field col segundo-campo">
          <label for="perfil" class="w-full">Perfil:</label>
          <input pInputText disabled [value]="publicador.perfil.nome" type="text" name="perfil" class="form-control" class="w-full" />
        </div>
      </div>

      <div class="md:flex m-2">
        <div class="field col primeiro-campo">
          <label for="senha" class="w-full">Alterar Senha:</label>
          <input pInputText formControlName="senha" type="password" name="senha" class="w-full" />
        </div>

        <div class="field col segundo-campo">
          <label for="confirmarSenha" class="w-full">Confirmar nova senha:</label>
          <input pInputText formControlName="confirmarSenha" type="password" name="confirmarSenha" class="w-full">
        </div>
      </div>


        <div class="md:flex justify-content-center">
            <button pButton type="submit" label="Salvar Alterações" (click)="editar()" class="p-button-raised p-button-secondary font-bold bg-blue-900 mb-2"></button>
        </div>
      </form>
  </div>
</div>
</div>
<p-toast></p-toast>
