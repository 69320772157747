import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from 'src/app/shared/config/storage_keys.config';
import { LocalUser } from 'src/app/shared/models/local_user';
import { StorageItem } from '../models/storage-item.model';
import { ObjUtil } from '../util/obj.util';

@Injectable({providedIn: 'root'})
export class StorageService {

  private storage = window.localStorage;
  constructor() { }


  public setItem(item: StorageItem<any>) {
    this.storage.setItem(item.id, JSON.stringify(item.data));
  }

  public getItem(id: string): any {
    const item = this.storage.getItem(id);
    return ObjUtil.isEmpty(item) ? null : JSON.parse(item);
  }

  getLocalUser() : LocalUser {
    let usr = localStorage.getItem(STORAGE_KEYS.localUser);
    if (usr == null) {
        return null;
    }
    else {
        return JSON.parse(usr);
    } 
}

setLocalUser(obj : LocalUser) {
    if (obj == null) {
        localStorage.removeItem(STORAGE_KEYS.localUser);
    }
    else {
        localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
    }
}

  public clearAll() {
    this.storage.clear(); 
  }
}
